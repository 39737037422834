import React from 'react';
import { InlineWidget } from 'react-calendly';

function GetStarted() {
  return (
    <section className="py-16 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-extrabold text-gray-900 text-center mb-8">
          Get Started: Book Your One-on-One Session with Sean
        </h2>
        <p className="text-xl text-gray-600 text-center mb-12">
          Take the first step towards your new career as a travel agent. Schedule a personalized one-on-one session with our expert, Sean, to discuss your opportunities in the exciting world of travel and tourism. Learn about industry trends, the role of a travel agent, and how to turn your passion into a rewarding career.
        </p>
        <div className="bg-white rounded-lg shadow-xl p-6">
          <InlineWidget
            url="https://calendly.com/sean-revolutiontravel/30min"
            styles={{
              height: '700px'
            }}
            onEventScheduled={() => {
              // Google Analytics event tracking
              if (window.gtag) {
                window.gtag('event', 'appointment_booked', {
                  'event_category': 'Engagement',
                  'event_label': 'One-on-One Session'
                });
              }
              // Facebook Pixel event tracking
              if (window.fbq) {
                window.fbq('track', 'ScheduleAppointment');
              }
            }}
          />
        </div>
      </div>
    </section>
  );
}

export default GetStarted;

